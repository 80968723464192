import config from 'config/app'
import lib from 'lib/commons'

import {
  CURRENT_USER_REQUEST_ERROR,
  CURRENT_USER_REQUEST_SUCCESS,
  CURRENT_USER_FETCHING,

  SPIN_ERROR,
  SPIN_SUCCESS,
  IS_SPINNING,

  CHECK_MATCH_ERROR,
  CHECK_MATCH_SUCCESS,
  IS_CHECKING_MATCH,

  GET_HISTORY_ERROR,
  GET_HISTORY_SUCCESS,
  IS_GETTING_HISTORY,

  CLAIM_ERROR,
  CLAIM_SUCCESS,
  IS_CLAIMING,

} from './actions'

const initialState = {
  loading: false,
  login: false,
  user: {},
  eventStarted: 0,
  googleForm: null,
  banner: [],
  kolNew:[],
  kolTop:[],
  missionVideo:[],
  popularVideo: [],
  prizeImage: null,
  spinActive: 0,
  spinPrize: [],
  term: [],
  video: [],
  videoPrize: [],
  videoPrizeImage: null,
  videoPrizeLimit: 0,
  faq: [],
  userHistory: [],

  isSpinning: false,
  isCheckingMatch: false,
  isGettingHistory: false,
  isClaiming: false,
}

export default function currentUserReducer(state = initialState,  { type, payload } = action) {
  switch (type) {
    case CURRENT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        login: true,
        user: payload.user,
        eventStarted: payload.event_started,
        googleForm: payload.google_form,
        banner: payload.image,
        kolNew: payload.kol_new,
        kolTop: payload.kol_top,
        missionVideo: payload.mission_video,
        popularVideo: payload.popular_video,
        prizeImage: payload.prize_image,
        spinActive: payload.spin_active,
        spinPrize: payload.spin_prize,
        term: payload.term,
        video: payload.video,
        videoPrize: payload.video_prize,
        videoPrizeImage: payload.video_prize_image,
        videoPrizeLimit: payload.video_prize_limit,
        faq: payload.faq,
      }
      break;
    case CURRENT_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        eventStarted: payload.event_started,
        googleForm: payload.google_form,
        banner: payload.image,
        kolNew: payload.kol_new,
        kolTop: payload.kol_top,
        missionVideo: payload.mission_video,
        popularVideo: payload.popular_video,
        prizeImage: payload.prize_image,
        spinActive: payload.spin_active,
        spinPrize: payload.spin_prize,
        term: payload.term,
        video: payload.video,
        videoPrize: payload.video_prize,
        videoPrizeImage: payload.video_prize_image,
        videoPrizeLimit: payload.video_prize_limit,
        faq: payload.faq,
      }
      break;
    case CURRENT_USER_FETCHING:
      return {
        ...state,
        loading: true
      }
      break;

    //SPIN
    case SPIN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          spin_times: payload.user.spin_times
        },
        isSpinning: false
      }
      break;
    case SPIN_ERROR:
      return {
        ...state,
        isSpinning: false
      }
      break;
    case IS_SPINNING:
      return {
        ...state,
        isSpinning: true
      }
      break;

    //CHECK MATCH
    case CHECK_MATCH_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          spin_times: payload.user.spin_times,
          view_times: payload.user.view_times
        },
        isCheckingMatch: false
      }
      break;
    case CHECK_MATCH_ERROR:
      return {
        ...state,
        isCheckingMatch: false
      }
      break;
    case IS_CHECKING_MATCH:
      return {
        ...state,
        isCheckingMatch: true
      }
      break;

      //GET HISTORY
      case GET_HISTORY_SUCCESS:
        return {
          ...state,
          userHistory: payload,
          isGettingHistory: false
        }
        break;
      case GET_HISTORY_ERROR:
        return {
          ...state,
          isGettingHistory: false
        }
        break;
      case IS_GETTING_HISTORY:
        return {
          ...state,
          isGettingHistory: true
        }
        break;

    //CLAIM
    case CLAIM_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          mission_video_history: payload.user.mission_video_history,
          view_times: payload.user.view_times,
        },
        isClaiming: false
      }
      break;
    case CLAIM_ERROR:
      return {
        ...state,
        isClaiming: false
      }
      break;
    case IS_CLAIMING:
      return {
        ...state,
        isClaiming: true
      }
      break;

    default:
      return state
  }
}
