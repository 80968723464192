import React, { useState, useEffect, useRef } from "react";
import { NavLink, useHistory, useParams, useLocation } from "react-router-dom";
import queryString from "query-string";

import config from "config/common";
import lib from "lib/commons";

const Header = ({
  lng,
  user: {
    user: {
      nickname = "",
      id: userId = null,
      uid = null,
      total_spin_times: totalSpinNum = 0,
      vote_history: voteHistory = [],
    } = {},
    matches = [],
    userHistory = [],
    eventStarted,
    isGettingHistory,
  } = {},

  getHistory,
}) => {
  let history = useHistory();
  const [visibleSection, setVisibleSection] = useState('section1');
  const headerRef = useRef(null);

  const sectionRefs = [
    { section: "section1"},
    { section: "section2"},
    { section: "section3"},
    { section: "section4"},
    { section: "section5"},
    { section: "section6"},
  ];

  const getDimensions = ele => {
    const height = ele ? ele.offsetHeight : 0;
    const offsetTop = ele ? ele.offsetTop : 0;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };

  const scrollTo = (e, ele) => {
    e.preventDefault();
    let section = document.getElementById(ele);
    section.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };


  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition = window.scrollY + headerHeight + 10;

      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = document.getElementById(section);
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection('section1');
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection]);

  return (
    <>
      <div id="header" ref={headerRef}>
        <div className="top-bar">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-2">
                <a href="https://fo4.garena.vn" className="logo">
                  <img src="/images/logo.png" alt="" />
                </a>
              </div>
              <div className="col-7">
                <span
                  className={`menu-link ${visibleSection === "section1" ? "selected" : ""}`}
                  onClick={(e) => {
                    scrollTo(e, 'section1')
                  }}
                >
                  Giới thiệu
                </span>
                <span
                  className={`menu-link ${visibleSection === "section2" ? "selected" : ""}`}
                  onClick={(e) => {
                    scrollTo(e, 'section2')
                  }}
                >
                  Hall of fame
                </span>
                <span
                  className={`menu-link ${visibleSection === "section3" ? "selected" : ""}`}
                  onClick={(e) => {
                    scrollTo(e, 'section3')
                  }}
                >
                  Chính sách & Đăng ký
                </span>
                <span
                  className={`menu-link ${visibleSection === "section4" ? "selected" : ""}`}
                  onClick={(e) => {
                    scrollTo(e, 'section4')
                  }}
                >
                  Video
                </span>
                <span
                  className={`menu-link ${visibleSection === "section5" ? "selected" : ""}`}
                  onClick={(e) => {
                    scrollTo(e, 'section5')
                  }}
                >
                  FAQ
                </span>
                <span
                  className={`menu-link ${visibleSection === "section6" ? "selected" : ""}`}
                  onClick={(e) => {
                    scrollTo(e, 'section6')
                  }}
                >
                  Quà tặng
                </span>
              </div>
              <div className="col-2 text-left">
                {!userId ? (
                  <a
                    href="/user/login"
                    className="btn-login"
                    onClick={(e) => {
                      if (!eventStarted) {
                        e.preventDefault();
                        lib.showMessage(config.demoMsg);
                        return false;
                      }
                    }}
                  >
                    Đăng nhập
                  </a>
                ) : (
                  <>
                    <span>
                      HI <strong className="nickname">{nickname}</strong>{" "}
                      {/* <span className="text-red">{totalSpinNum}</span> */}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          {userId && (
            <a href="/user/logout" className="btn-logout">
              Đăng xuất
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
