export default {
  vi: {
    domain: "fvpl.fo4.garena.vn",
    subTitle1:
      'Hoàn thành 4 trận đấu nhiệm vụ hàng ngày để thu thập các lượt quay quà. Số lượt quay này sẽ được tích lũy, giúp bạn nhận thêm quà các mốc. *Lưu ý: Sự kiện Tích Lũy Quay Qùa sẽ kết thúc vào 23h59 ngày 20.2.2022',
    subTitle2:
      "Chọn đội tuyển yêu thích của bạn và cổ vũ họ trong chặng đường sắp tới. Với bất kì chiến thắng nào của đội tuyển, bạn sẽ nhận được 10 điểm đổi quà.",
    subTitle3:
      "Trổ tài dự đoán tỉ số các trận đấu tại FVPL Mùa Xuân 2022. Với mỗi dự đoán chính xác sẽ đem về 20 điểm đổi quà.",
    subTitle4:
      "Cố gắng tích lũy nhiều điểm nhất có thể để đua top BXH toàn server. Top 100 HLV đạt được số điểm cao nhất sẽ nhận về những phần thưởng vô cùng giá trị",

    notice: "Thông báo",
    confirm: "Xác nhận",
    cancel: "Huỷ",
    congrats: "Chúc mừng!!!",
    claimMessage: `Bạn sẽ nhận được <span class="highlight"><strong>[xxx]</strong></span><br>sau khi nhấn "Xác nhận"`,
    youReceived: "Bạn đã nhận được",
    youReceivedMain: "Bạn đã nhận được vật phẩm chính",
    defaultError: "Hiện không thể thực hiện, vui lòng thử lại sau",
    not_logged_in: "Bạn chưa đăng nhập",
    missing_account_id: "Tài khoản của bạn chưa đăng nhập vào game",
    region_invalid: "Khu vực không được hỗ trợ cho sự kiện",
    version_incompatible:
      "Sự kiện đã được nâng cấp. Bạn vui lòng tải lại sự kiện",
    item_not_found: "Không tìm thấy vật phẩm",
    item_invalid: "Chưa đến thời điểm nhận vật phẩm",
    item_unavailable: "Chưa đến thời điểm nhận vật phẩm",
    event_unstarted: "Sự kiện chưa bắt đầu",
    event_ended: "Sự kiện đã kết thúc",
    already_shared: "Bạn đã chia sẻ trước đó rồi!",
    team_playing:
      "Tính năng này được tạm khóa để cập nhật kết quả, vui lòng quay lại sau",
    not_contributed: "Hãy chơi 1 trận",
    spin_ended:
      "Sự kiện tích điểm đổi quà đã kết thúc",
    user_processing: "Hệ thống đang trao điểm, vui lòng thử lại sau 10 phút",
  },
  en: {
    domain: "salehunt.ff.garena.vn",
    logoSite: "/images/logo-pk.png",
    saleTag: "Sale",
    refreshSale: "Refresh discount",
    buyText: "Buy",
    boughtText: "Bought",
    cantBuyText: "Can not buy",
    guideText: "Guide text...",
    ticketLabel: "Sale off ticket",
    confirmBuy: `Do you want to buy this item?<br>Select your discount ticket`,
    warning:
      "Bạn chưa chọn vé giảm giá<br>bạn có muốn tiếp tục mua vật phẩm không?",
    rerollConfirm:
      'Use [xxx] <img src="/images/icon-diamond.png" class="icon-diamond" alt=""/> to refresh?',
    purchasedError: "You have already used this discount ticket",
    rerollSuccess: "Successfully refresh",
    ruleTitle: "Information",
    rule: "<p>Lorem</p>",
    historyTitle: "History",
    timeField: "Time",
    itemField: "Item",
    priceField: "Price",
    noHistory: "No history",
    menuHome: "Home",

    notice: "Notice",
    confirm: "Confirm",
    cancel: "Cancel",
    congrats: "Gongratulation!!!",
    claimMessage: `Bạn sẽ nhận được <span class="highlight"><strong>[xxx]</strong></span><br>sau khi nhấn "Xác nhận"`,
    youReceived: "You received",
    youReceivedMain: "You received main prize",
    defaultError: "Error occurred, please try again later",
    unauthorized: "Please login",
    account_not_found: "Your account is not activated ingame",
    region_invalid: "Region is invalid",
    version_incompatible: "Event is upgraded. Please reload your site",
    item_not_found: "No item found",
    item_invalid: "Item is invalid",
    item_unavailable: "Item is not available yet",
    event_unstarted: "Event has not started yet",
    event_stopped: "Event has ended",
    limit_exceeded: "You have reach the item limitation",
    packs_purchased: "You have already bought this pack",
    reroll_limit_exceeded: "You have reach refresh limitation",
    pack_not_found: "No pack found",
    discount_not_found: "Discount rate is not exist",
    discount_used: "Discount have already used",
    spent_unreached: "You need to spent more to buy this item",
    discount_unapplicable: "Discount can not be applied",
  },
};
