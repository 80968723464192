import request from "lib/request"
import config from "config/app"
import lib from 'lib/commons'
import lang from "lng/index"
const lng = lang[config.lng]

export const CURRENT_USER_REQUEST         = 'CURRENT_USER_REQUEST'
export const CURRENT_USER_REQUEST_ERROR   = 'CURRENT_USER_REQUEST_ERROR'
export const CURRENT_USER_REQUEST_SUCCESS = 'CURRENT_USER_REQUEST_SUCCESS'
export const CURRENT_USER_FETCHING        = 'CURRENT_USER_FETCHING'

export const SPIN         = 'SPIN'
export const SPIN_ERROR   = 'SPIN_ERROR'
export const SPIN_SUCCESS = 'SPIN_SUCCESS'
export const IS_SPINNING  = 'IS_SPINNING'

export const CHECK_MATCH         = 'CHECK_MATCH'
export const CHECK_MATCH_ERROR   = 'CHECK_MATCH_ERROR'
export const CHECK_MATCH_SUCCESS = 'CHECK_MATCH_SUCCESS'
export const IS_CHECKING_MATCH   = 'IS_CHECKING_MATCH'

export const GET_HISTORY         = 'GET_HISTORY'
export const GET_HISTORY_ERROR   = 'GET_HISTORY_ERROR'
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS'
export const IS_GETTING_HISTORY  = 'IS_GETTING_HISTORY'

export const CLAIM         = 'CLAIM'
export const CLAIM_ERROR   = 'CLAIM_ERROR'
export const CLAIM_SUCCESS = 'CLAIM_SUCCESS'
export const IS_CLAIMING   = 'IS_CLAIMING'

export const isCurrentUserFetching = () => {
  return {
    type: CURRENT_USER_FETCHING
  }
}

export const getCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch(isCurrentUserFetching());
    request('api/user/get').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getCurrentUserSuccess(response));
      } else {
        if(response.error_code == 'no_account') {
          lib.showDownlad()
        }
        dispatch(getCurrentUserError(response))
      }
    })
  }
}

export const getCurrentUserSuccess = (response) => {
  return {
    type: CURRENT_USER_REQUEST_SUCCESS,
    payload: response.payload
  }
}

export const getCurrentUserError = (response) => {
  return {
    type: CURRENT_USER_REQUEST_ERROR,
    payload: response.payload
  }
}

//Spin
export const isSpinning = (response) => {
  return {
    type: IS_SPINNING,
  }
}
export const spin = (callback = () => {}) => {
  return (dispatch, getState) => {
    dispatch(isSpinning());
    request('api/user/spin', 'POST', {
      body: JSON.stringify({})
    }).then(function(response) {
      if(response.status == 'successful') {

        console.log(getState())
        let reward = response.payload.reward
        setTimeout(() => {
            dispatch(spinSuccess(response))
        }, 8000)
        callback(response.payload);
      } else {
        lib.showError(response.error_code)
        dispatch(spinError(response))
      }
    })
  }
}
export const spinSuccess = (response) => {
  return {
    type: SPIN_SUCCESS,
    payload: response.payload
  }
}
export const spinError = (response) => {
  return {
    type: SPIN_ERROR,
    payload: response.payload
  }
}

//get history
export const isGettingHistory = () => {
  return {
    type: IS_GETTING_HISTORY
  }
}

export const getHistory = () => {
  return (dispatch, getState) => {
    dispatch(isGettingHistory());
    request('api/user/history').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getHistorySuccess(response))
      } else {
        dispatch(getHistoryError(response))
      }
    })
  }
}

export const getHistorySuccess = (response) => {
  return {
    type: GET_HISTORY_SUCCESS,
    payload: response.payload
  }
}

export const getHistoryError = (response) => {
  return {
    type: GET_HISTORY_ERROR,
    payload: response.payload
  }
}

//Claim
export const isClaiming = (response) => {
  return {
    type: IS_CLAIMING,
  }
}
export const claim = (videoIndex) => {
  return (dispatch, getState) => {
    dispatch(isClaiming());
    request('api/user/claim', 'POST', {
      body: JSON.stringify({
        video_index: parseInt(videoIndex)
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        let reward = response.payload.reward;
        lib.showMessage(`Bạn đã nhận được:<br><div class="item-cover"><img src="${reward.image}" alt="" class="item-cover__img" /></div><p>${reward.product_name}</p>`, null, 'Chúc mừng').then(res => {
          dispatch(claimSuccess(response))
        })

      } else {
        lib.showError(response.error_code)
        dispatch(claimError(response))
      }
    })
  }
}
export const claimSuccess = (response) => {
  return {
    type: CLAIM_SUCCESS,
    payload: response.payload
  }
}
export const claimError = (response) => {
  return {
    type: CLAIM_ERROR,
    payload: response.payload
  }
}

export const isCheckingMatch = (response) => {
  return {
    type: IS_CHECKING_MATCH,
  }
}
export const checkMatch = () => {
  return (dispatch, getState) => {
    dispatch(isCheckingMatch());
    request('api/user/update-daily', 'POST', {
      body: JSON.stringify({})
    }).then(function(response) {
      if(response.status == 'successful') {
        lib.showMessage(`
          <div class="row match-progress">
            <div class="col-6">
              <p class="text-right">Hoàn thành trận <br/>xh, glxh, volta</p>
            </div>
            <div class="col-6 text-left">
              <img src="/images/play${response.payload.total_play}.png" alt="" class="play-progress" />
            </div>
          </div>
        `, null, 'Tiến độ nhiệm vụ', null, 'popup-check-match').then(res => {
          dispatch(checkMatchSuccess(response))
        })
      } else {
        lib.showError(response.error_code)
        dispatch(checkMatchError(response))
      }
    })
  }
}
export const checkMatchSuccess = (response) => {
  return {
    type: CHECK_MATCH_SUCCESS,
    payload: response.payload
  }
}
export const checkMatchError = (response) => {
  return {
    type: CHECK_MATCH_ERROR,
    payload: response
  }
}
